import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/rileyuy/Documents/GitHub/ep-vts-website/src/components/Layout/Layout.js";
import { Link } from "gatsby";
import Container from "layout/Container";
import SEO from "layout/SEO";
import Collapsible from "elements/Collapsible";
import ContactUs from "../components/StaticPages/HelpCenter/ContactUs";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <Container isCentered mdxType="Container">
      <SEO title="Help Center" mdxType="SEO" />
      <h2>{`Frequently Asked Questions`}</h2>
      <hr />
      <h4>{`Order`}</h4>
      <Collapsible title="How do I order?" mdxType="Collapsible">
        <p>{`You can order your medicine by uploading your prescription and searching for your medicines.`}</p>
        <ol>
          <li parentName="ol">{`On the home page of viatris.medgrocer.com, click the “Order Medicines” button.`}</li>
          <li parentName="ol">{`Upload attachments such as prescription and employee ID.`}</li>
          <li parentName="ol">{`Search for the medicine you want.`}</li>
          <li parentName="ol">{`Indicate the quantity you want to order and click Add to cart.`}</li>
          <li parentName="ol">{`Repeat Steps 3 and 4 for any other medicine you want to purchase then proceed to checkout.`}</li>
          <li parentName="ol">{`Fill out your information, payment method, and delivery address. Click Next.`}</li>
          <li parentName="ol">{`Read our terms and conditions, tick the box if you agree, and then click Submit.`}</li>
          <li parentName="ol">{`You’ll receive an email summarizing your order.`}</li>
          <li parentName="ol">{`Wait for the confirmation from our pharmacist to verify your order.`}</li>
          <li parentName="ol">{`After you confirm, we'll deliver your order within 2-5 working days.`}</li>
        </ol>
      </Collapsible>
      <Collapsible title="How would I know if my order went through?" mdxType="Collapsible">
        <p>{`You will receive a confirmation email immediately after ordering. When your order is being processed, our pharmacist will contact you to verify your order. If you do not receive a confirmation from MedGrocer within two working days, you may contact us at viatris.med@medgrocer.com`}</p>
      </Collapsible>
      <Collapsible title="How long does it take to process my order?" mdxType="Collapsible">
        <p>{`Orders will be processed on Monday to Friday, 8AM to 4PM. Orders made during that time are processed within two working days.`}</p>
      </Collapsible>
      <Collapsible title="When will I receive my medicines?" mdxType="Collapsible">
        <p>{`Deliveries will take 1-4 working days for NCR and 2-7 working days outside NCR. You will receive a message when your medicines are ready for pick-up or delivery.`}</p>
      </Collapsible>
      <Collapsible title="Can I modify my order?" mdxType="Collapsible">
        <p>{`To prevent miscommunication and minimize inaccuracies, requests are considered final upon submission. For any revisions, you may send an email to viatris.med@medgrocer.com requesting to cancel your order and submit a new request through viatris.medgrocer.com.`}</p>
      </Collapsible>
      <Collapsible title="How do I cancel my order?" mdxType="Collapsible">
        <p>{`You may only cancel your order if it has not yet been shipped. Contact us at viatris.med@medgrocer.com to confirm the status of your order. If your order can be cancelled, you will receive an email that your order has been cancelled.`}</p>
      </Collapsible>
      <Collapsible title="Can I order for someone else?" mdxType="Collapsible">
        <p>{`Only active employees and registered dependents of Viatris can order avail of this benefit. You may go to our website, medgrocer.com, to avail of medicines outside the medicine program.`}</p>
      </Collapsible>
      <Collapsible title="Is there a minimum order?" mdxType="Collapsible">
        <p>{`There's no minimum order for delivery.`}</p>
      </Collapsible>
      <h4>{`Prescription`}</h4>
      <Collapsible title="Do I need a prescription to buy medicines?" mdxType="Collapsible">
        <p>{`Yes. Just like any pharmacy, we require a valid prescription that contains the following details: prescription date, patient’s name, prescriber’s name, and prescriber’s license number.`}</p>
        <p>{`All medicines ordered in the program require a prescription.`}</p>
      </Collapsible>
      <Collapsible title="How do I send my prescription?" mdxType="Collapsible">
        <p>{`You can upload a picture or a scanned copy of your prescription when you request your medicines.`}</p>
      </Collapsible>
      <h4>{`Delivery`}</h4>
      <Collapsible title="What are the areas covered for delivery? Is there a convenience fee?" mdxType="Collapsible">
        <p>{`We currently offer nationwide delivery for free.`}</p>
      </Collapsible>
      <h4>{`Returns`}</h4>
      <Collapsible title="What is your return policy?" mdxType="Collapsible">
        <p>{`All products sold on MedGrocer are subject to quality checks by our purchasing, storage, assembly, and delivery teams. Please inspect your order upon delivery. If your medicines are damaged upon delivery, you may refuse to accept the medicines.`}</p>
        <p>{`On a case-to-case basis, we can process requests to return or exchange the medicines within seven days upon delivery. Please make sure that you keep your delivery receipt and the medicines you want to return.`}</p>
      </Collapsible>
      <Collapsible title="What medicines will you accept for returns?" mdxType="Collapsible">
        <p>{`We will replace damaged or wrongly dispensed medicines.`}</p>
      </Collapsible>
      <Collapsible title="Can I return an item after seven days?" mdxType="Collapsible">
        <p>{`Any problems encountered seven days after the delivery may not be accommodated under our Return Policy. We highly encourage you to review and inspect your medicines upon delivery so that our courier could immediately facilitate the return, exchange, or refund request.`}</p>
      </Collapsible>
      <Collapsible title="How long is the processing time for returns?" mdxType="Collapsible">
        <p>{`In Metro Manila, returns are processed within two to five working days. For provincial locations, return orders are processed within 10 working days.`}</p>
      </Collapsible>
      <h4>{`Privacy`}</h4>
      <Collapsible title="What information do you share?" mdxType="Collapsible">
        <p>{`Only coordinators processing your orders will have access to your medical information to ensure your medicines are properly dispensed. We may use anonymized data for analytics to improve our services or collaborate with partners.`}</p>
      </Collapsible>
      <Collapsible title="How do I contact the Data Privacy Officer?" mdxType="Collapsible">
        <p>{`MedGrocer’s Data Privacy Officer provides support with any data privacy related questions, comments, concerns, or complaints. You may contact MedGrocer’s Data Privacy Officer through the following channels:`}</p>
        <p>{`MedGrocer 24F Centuria Medical Makati, Kalayaan cor. Salamanca St., Makati City
Email address: dpo@medgrocer.com`}</p>
      </Collapsible>
      <h4>{`Regulatory`}</h4>
      <Collapsible title="What is MedGrocer?" mdxType="Collapsible">
        <p>{`MedGrocer is an FDA-licensed online pharmacy. Simply order your medicines online and we’ll deliver them to you.`}</p>
      </Collapsible>
      <Collapsible title="Are your medicines FDA-certified?" mdxType="Collapsible">
        <p>{`Yes. The Certificate of Product Registration of a particular medicine can be found at the website of the Food and Drug Administration.`}</p>
      </Collapsible>
    </Container>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      